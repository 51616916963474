
body {
    display: flex;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #fafafa;
}

noscript {
    position: fixed;
    display: block;
    width: 80%;
    height: 80%;
    margin: 5%;
    padding: 5%;
    font-family: 'Roboto', sans-serif;
}

.card-primary-content {
    padding: 1em;
}

.javascript-required {
    background-image: url("images/aleksandar-cvetanovic-cw_uvISXkCI-unsplash-scaled.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: contain;
    display: flex;
    align-items: flex-end;
}

.javascript-required__title {
    padding: 0 5%;
    width: 100%;
}

.javascript-required h2 {
    display: block;
    color: white;
    font-weight: 500;
}

/*@font-face {*/
/*    font-family: 'logos';*/
/*    src: url('fonts/logos.woff') format('woff');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*    font-display: block;*/
/*}*/

/*.icon-haproxy:before {*/
/*    font-family: 'logos';*/
/*    font-style: normal;*/
/*    content: "\e900";*/
/*}*/

/*.icon-nginx:before {*/
/*    font-family: 'logos';*/
/*    font-style: normal;*/
/*    content: "\e901";*/
/*}*/
